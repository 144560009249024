
const state = {
  commissions: [],
  isLoading: null
}

const mutations = {
  updateList(state, _commissions){
    state.commissions = _commissions
  }
}

const actions = {
  loadCommissionsFast({commit, dispatch, rootState}, callback){
      rootState.AppStore.axiosInstanceUnsecure.get('commissions/light?size=25')
      .then(response => {
        // JSON responses are automatically parsed.
        if(response.data._embedded) {
          for(let commission of response.data._embedded.data){
            if(!commission.administrators)
              commission.administrators = []
            if(!commission.authorizedSubscribers)
              commission.authorizedSubscribers = []
            if(!commission.emailConfiguration)
              commission.emailConfiguration = {}
          }
          commit('updateList', response.data._embedded.data)
        }
      })
      .catch(e => {
        console.log(e)
      })
  },
  loadCommissions({commit, rootState}, callback){
    state.isLoading = rootState.AppStore.axiosInstance.get('commissions?size=25');
    
    state.isLoading.then(response => {
      // JSON responses are automatically parsed.
      if(response.data._embedded) {
        for(let commission of response.data._embedded.data){
          if(!commission.administrators)
            commission.administrators = []
          if(!commission.authorizedSubscribers)
            commission.authorizedSubscribers = []
          if(!commission.emailConfiguration)
            commission.emailConfiguration = {}
        }
        commit('updateList', response.data._embedded.data)
        state.isLoading = null;
      }
      if(callback)
        callback()
    })
    .catch(e => {
      console.log(e)
    })
  },
  loadCommissionFromName({state, commit, rootState}, commissionName){
    const commission = state.commissions.find(commission => commission.technicalName == commissionName);
    if(!commission){
      return rootState.AppStore.axiosInstance.get('commissions?search=technicalName=='+commissionName)
    } else {
      return new Promise((resolve, reject) => {
        resolve({"data": {"_embedded": {"data": [commission]}}})
      })
    }
  }
}

const getters = {
  getCommissionFromId: (state) => (id) => {
    let commission = state.commissions.find(commission => commission.id == id)
    if(!commission)
      return {id: id, emailConfiguration: {}}
    return commission
  },
  getCommissionFromName: (state) => (name) => {
    let commission = state.commissions.find(commission => commission.technicalName == name)
    if(!commission)
      return {id: 0}
    return commission
  }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
