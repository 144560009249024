
const state = {
  resources: [],
  specLoadedResources: {},
  isSearching: []
}

const mutations = {
  updateList(state, _resources){
    state.resources = _resources
  },
  updateSpecLoadedResources(state, _resource){
    const tmpArr = state.specLoadedResources;
    state.specLoadedResources = [];
    tmpArr[_resource.id] = _resource;
    state.specLoadedResources = tmpArr;
  }
}

const actions = {
  loadResources({commit, rootState}, filterSort){

    let filters = ''
    if(filterSort){
      filters = '?'
      if(filterSort.filters){
          filters += filterSort.filters
          if(filterSort.sort)
            filters += '&'+filterSort.sort
      }else if(filterSort.sort){
        filters += filterSort.sort
      }
    }

    rootState.AppStore.axiosInstance.get('resources'+filters).then(response => {
      if(response.data._embedded) {
        commit('updateList', response.data._embedded.data)
      } else {
        commit('updateList', [])
      }
    })
    .catch(e => {
      console.log(e)
    })
  },
  loadResource({state, commit, rootState}, resourceId){
    if(!state.isSearching.includes(resourceId) && !state.specLoadedResources[resourceId]){
      state.isSearching.push(resourceId)
      if(resourceId!=0){
        return rootState.AppStore.axiosInstance.get('/resources/'+resourceId).then(response => {
          commit('updateSpecLoadedResources', response.data)
          state.isSearching.splice( state.isSearching.indexOf(resourceId), 1 )
        })
        .catch(e => {
          console.log(e)
          commit('updateSpecLoadedResources', {id: resourceId})
          state.isSearching.splice( state.isSearching.indexOf(resourceId), 1 )
        })
      }
    }
  }
}

const getters = {
  getResourceFromId: (state) => (loader) => {
    let resource = state.resources.find(resource => resource.id == loader.id)
    if(!resource)
      resource = state.specLoadedResources[loader.id]
    if(!resource){
      if(loader.callback)
        loader.callback(loader.id)
      return {id: loader.id}
    }
    return resource
  }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
