<template>
  <div id="app">
    <div v-if="isAuthenticated && !validateorsubscribe">
      <nav class="navbar navbar-expand-xl navbar-dark bg-dark fixed-top">
        <a class="navbar-brand" href="/"><img src="@/assets/WH2.png" alt="ASPR" style="height:35px;"/></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarsExampleDefault">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item active">
                  <router-link class="nav-link" to="/" data-toggle="collapse" data-target=".navbar-collapse.show">Accueil<span class="sr-only">(current)</span></router-link>
              </li>
              <li class="nav-item dropdown" v-if="hasRole({names: commissionsNames, roles: ['ADMIN', 'SUPER-ADMIN']})">
                  <a class="nav-link dropdown-toggle" href="#" id="comAdmin" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Administration de Commission</a>
                  <div class="dropdown-menu" aria-labelledby="comAdmin">
                    <router-link
                      v-for="commission in adminCommissions"
                      class="dropdown-item" :to='"/management/commissions/commissionWrapper/"+commission.technicalName+"/"+commission.id'
                      :key="commission.id"
                      data-toggle="collapse" data-target=".navbar-collapse.show">
                        {{commission.name}}
                      </router-link>
                  </div>
              </li>
              <li class="nav-item dropdown" v-if="hasRole({names: commissionsNames, roles: ['ADMIN', 'SUPER-ADMIN']})">
                  <a class="nav-link dropdown-toggle" href="#" id="dropdown02" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Administration</a>
                  <div class="dropdown-menu" aria-labelledby="dropdown02">
                    <router-link class="dropdown-item" to="/management/general" v-if="hasRole({names: ['general'], roles: ['ADMIN', 'SUPER-ADMIN']})"
                      data-toggle="collapse" data-target=".navbar-collapse.show">Générale</router-link>
                    <router-link class="dropdown-item" to="/management/news" v-if="hasRole({names: commissionsNames, roles: ['ADMIN', 'SUPER-ADMIN']})"
                      data-toggle="collapse" data-target=".navbar-collapse.show">Informations</router-link>
                    <router-link class="dropdown-item" to="/management/persons" v-if="hasRole({names: ['general'], roles: ['ADMIN', 'SUPER-ADMIN']})"
                      data-toggle="collapse" data-target=".navbar-collapse.show">Personnes</router-link>
                    <router-link class="dropdown-item" to="/management/photos" v-if="hasRole({names: commissionsNames, roles: ['ADMIN', 'SUPER-ADMIN']})"
                      data-toggle="collapse" data-target=".navbar-collapse.show">Photos</router-link>
                  </div>
              </li>
              <li class="nav-item dropdown" v-if="genericCommissions.length>0">
                  <a class="nav-link dropdown-toggle" href="#" id="dropdown01" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Commissions</a>
                  <div class="dropdown-menu" aria-labelledby="dropdown01">
                    <!--
                    <router-link v-if="hasRole({names: ['tools'], roles: ['USER', 'ADMIN', 'SUPER-ADMIN'], subscriptions: ['AMICALISTE']})" class="dropdown-item" to="/toolsReservation">Matériel</router-link>
                    <router-link v-if="hasRole({names: ['vehicles'], roles: ['USER', 'ADMIN', 'SUPER-ADMIN'], subscriptions: ['AMICALISTE']})" class="dropdown-item" to="/vehicleReservation">Véhicules</router-link>
                    <router-link v-if="hasRole({names: ['calendars'], roles: ['USER', 'ADMIN', 'SUPER-ADMIN'], subscriptions: ['AMICALISTE']})" class="dropdown-item" to="/calendarsInscription">Calendriers</router-link>
                    <router-link v-if="hasRole({names: ['children'], roles: ['USER', 'ADMIN', 'SUPER-ADMIN'], subscriptions: ['AMICALISTE']})" class="dropdown-item" to="/">Enfants</router-link>
                    <router-link v-if="hasRole({subscriptions: ['AMICALISTE', 'HONORAIRE']})" class="dropdown-item" to="/">Sainte Barbe</router-link>
                    -->
                    <router-link
                      v-for="commission in genericCommissions"
                      class="dropdown-item"
                      :to="{ name: 'commissionGeneric', params: { commission: commission, commissionName:commission.technicalName, commissionId: commission.id}}"
                      :key="commission.id"
                      data-toggle="collapse" data-target=".navbar-collapse.show"
                      >
                      {{commission.name}}
                    </router-link>

                  </div>
              </li>
              <li class="nav-item">
                  <router-link class="nav-link" to="/photos" data-toggle="collapse" data-target=".navbar-collapse.show">Photos<span class="sr-only">(current)</span></router-link>
              </li>
              <li class="nav-item">
                  <router-link class="nav-link" to="/documents" data-toggle="collapse" data-target=".navbar-collapse.show">Documents</router-link>
              </li>
              <li class="nav-item">
                  <router-link class="nav-link" to="/partners" data-toggle="collapse" data-target=".navbar-collapse.show">Partenaires</router-link>
              </li>
            </ul>

            <ul class="navbar-nav mr-2">
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="dropdownUser" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{userName}}</a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownUser">
                  <router-link class="dropdown-item" :to="'/accountWrapper/'+userId"
                    data-toggle="collapse" data-target=".navbar-collapse.show">Mon Compte</router-link>
                  <a class="dropdown-item" @click="logMeOut()" style="cursor: pointer;"
                    data-toggle="collapse" data-target=".navbar-collapse.show">Déconnexion</a>
                </div>
              </li>
            </ul>
            <a href="#" @click="recapCartVisible = !recapCartVisible" class="mr-2" data-toggle="collapse" data-target=".navbar-collapse.show">
              <i class="fas fa-shopping-cart fa-1x" style="color: white;cursor: pointer;"></i>
            </a>
            <!--
            &nbsp;
            <router-link to="/management/commissions/calendarCommandDetails/0" data-toggle="collapse" data-target=".navbar-collapse.show">
              <i class="fas fa-calendar-check" style="color: white;cursor: pointer;"></i>
            </router-link>
            -->
        </div>
      </nav>

      <div class="col-md-3" v-if="recapCartVisible" style="position: fixed;right: 0px; top: 48px;z-index: 1">
        <RecapCart :showRecapCart="showRecapCart"></RecapCart>
      </div>

      <br/>
      <!-- route outlet -->
      <!-- component matched by the route will render here -->
      <div class="container-fluid">
          <router-view :key="$route.fullPath" :showRecapCart="showRecapCart"></router-view>
      </div>
    </div>
    <div v-else-if="buyCal" style="margin-top: -60px;">
      <CalendarPurchase></CalendarPurchase>
    </div>
    <div v-else-if="calendarCode" style="margin-top: -60px;">
      <CalendarCode></CalendarCode>
    </div>
    <div v-else-if="validateorsubscribe">
      <EmailValidationAndSubscription></EmailValidationAndSubscription>
    </div>
    <div v-else-if="renewsubscription">
      <AccountConfirmationAndSubscriptionRenewal></AccountConfirmationAndSubscriptionRenewal>
    </div>
    <div v-else>
      <login></login>
    </div>
    <footer class="page-footer font-small blue-grey lighten-5">
      <Footer></Footer>
    </footer>
    <cookie-law
      theme="blood-orange--rounded"
      message="Ce site utilise uniquement les cookies nécessaires à son fonctionnement"
      buttonText="OK"></cookie-law>
  </div>
</template>

<script>

import { mapState, mapGetters, mapActions } from 'vuex'
import Login from './components/others/Login.vue'
import CalendarPurchase from './components/others/CalendarPurchase.vue'
import CalendarCode from './components/others/CalendarCode.vue'
import EmailValidationAndSubscription from './components/EmailValidationAndSubscriptionPayment.vue'
import AccountConfirmationAndSubscriptionRenewal from './components/AccountConfirmationAndSubscriptionRenewal.vue'
import RecapCart from './components/others/RecapCart.vue'
import Footer from './Footer.vue'
import CookieLaw from 'vue-cookie-law'

export default {
  name: 'app',
  computed: {
    ...mapState({
      accessIp: state => state.AppStore.accessIp,
      accessPort: state => state.AppStore.accessPort,
      userId: state => state.AppStore.userId,
      userName: state => state.AppStore.userName,
      commissions: state => state.CommissionStore.commissions,
      tokenExpiration: state => state.AppStore.tokenExpiration
    }),
    ...mapGetters({
      'isAuthenticated': 'AppStore/isAuthenticated',
      hasRole: 'AppStore/hasRole'
    }),
    commissionsNames(){
      let names = []
      for(let commission of this.commissions){
        names.push(commission.technicalName)
      }
      return names
    },
    genericCommissions(){
      let _commissions = []
      for(let commission of this.commissions){
        if(!commission.subType && commission.active && this.hasRole({name: [commission.technicalName], roles: ['USER', 'ADMIN', 'SUPER-ADMIN'], subscriptions: commission.authorizedSubscribers}))
          _commissions.push(commission)
      }
      return _commissions.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()))
    },
    adminCommissions(){
      let _commissions = []
      for(let commission of this.commissions){
        if(this.hasRole({names: [commission.technicalName], roles: ['ADMIN', 'SUPER-ADMIN']}))
          _commissions.push(commission)
      }
      return _commissions.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()))
    }
  },
  methods: {
    ...mapActions({
      updateAccessIp: 'AppStore/updateAccessIp',
      updateAccessPort: 'AppStore/updateAccessPort',
      logout: 'AppStore/logOut',
      updateUser: 'AppStore/updateUser',
      updateTokenExpirationDate: 'AppStore/updateTokenExpirationDate',
      updateProtocolAndUnsecure: 'AppStore/updateProtocolAndUnsecure',
      loadRGPD: 'AppStore/loadRGPD'
    }),
    goToCart: function(){
      this.$router.push({name:'cart'})
    },
    goToCalendarPurchaseValidation: function(){
      this.$router.push({name:'calendarCommandDetails'})
    },
    logMeOut: function(){
      localStorage.userName = ''
      localStorage.jwtToken = ''
      localStorage.expirationDate = 0
      this.logout()
    },
    showRecapCart(value){
      if(value)
        this.recapCartVisible = true
      else {
        this.recapCartVisible = false
      }
    }
  },
  data: function(){
    return {
      buyCal: false,
      calendarCode: false,
      validateorsubscribe: false,
      recapCartVisible: false,
      renewsubscription: false
    }
  },
  mounted(){
    this.updateAccessIp(location.hostname)
    this.updateAccessPort(location.port)
    this.updateProtocolAndUnsecure(location.protocol)
    
    if (localStorage.jwtToken && localStorage.userName) {
      let subscriptions=[]
      if(localStorage.subscriptions){
        subscriptions=localStorage.subscriptions.split(",")
      }
      let user = {username: localStorage.userName, token: localStorage.jwtToken, userId: localStorage.userId, roles: localStorage.roles, subscriptions: subscriptions, accountValidity: localStorage.accountValidity}
      this.updateUser(user)
      this.updateTokenExpirationDate(localStorage.expirationDate)
    }

    //alert(window.location.href)
    if(window.location.href.endsWith("/calendrier") || window.location.href.includes("/calendrier#")){
      this.buyCal = true
    }else if(window.location.href.includes("/calendrier/code")){
      this.calendarCode = true
    }else if(window.location.href.includes("validateorsubscribe")){
      this.validateorsubscribe = true
    }else if(window.location.href.includes("renewsubscription")){
      this.renewsubscription = true
    }
    this.loadRGPD()
    
    if(!this.isAuthenticated){//preload some data before log in to display things fast when someone connects
      this.$store.dispatch('CommissionStore/loadCommissionsFast')
    } else { // page reload for an authenticated user, reload everythin
      this.$store.dispatch('CommissionStore/loadCommissions')
    }
  },
  updated(){
    let curTimestamp = new Date().getTime()
    if(this.tokenExpiration && this.tokenExpiration < curTimestamp/1000){
      this.logMeOut()
    }
  },
  components: {
    'login': Login,
    CalendarPurchase,
    CalendarCode,
    EmailValidationAndSubscription,
    AccountConfirmationAndSubscriptionRenewal,
    RecapCart,
    Footer,
    CookieLaw
  }
}
</script>

<style>
#app {
  margin-top: 60px;
}
</style>
